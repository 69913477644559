import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ImageSKUMinimalData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ImageSKUMinimalDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ImageSKUMinimalDataStore",
			variables: true,
		})
	}
}

export async function load_ImageSKUMinimalData(params) {
	await initClient()

	const store = new ImageSKUMinimalDataStore()

	await store.fetch(params)

	return {
		ImageSKUMinimalData: store,
	}
}
