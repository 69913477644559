import type { ProductHandles$result } from '$houdini';
import { getBrancheFromCollections } from '.';
import { resolveRoute } from '$app/paths';
import { page } from '$app/stores';
import { get } from 'svelte/store';
import { trimSDS } from '$lib/utils/sds';
import slug from 'slug';

/**
 * Returns the path to the product. Can be used for the href prop in Nextjs' Link component.
 */
export const getProductPath = (handle: string, title: string, variantSku?: string) => {
	let path = resolveRoute('/[locale]-[country]/[branch=branch]/[slug=slug]/[handle]', {
		...get(page).params,
		handle: trimSDS(handle),
		slug: slug(title),
		branch: 'hund' // TODO: replace with getCollectionPath
	});

	if (variantSku) {
		path += `?variantSKU=${variantSku}`;
	}
	return path;
};
