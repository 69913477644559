<script lang="ts">
	import {
		getImageDimensions,
		type ImageStoryblok,
		type ShopTheLookPoint as ShopTheLookPointData
	} from '$lib/storyblok/types';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import Image from '$lib/components/Image/Image.svelte';
	import ShopTheLookPoint from '$lib/components/Storyblok/ShopTheLook/ShopTheLookPoint.svelte';
	import { onMount } from 'svelte';
	import { activeShopTheLookPoint } from '$lib/store/activeShopTheLookPoint.store';

	export let asset: ImageStoryblok;
	export let points: Array<ShopTheLookPointData>;
	let className: string = '';
	export { className as class };

	$: imageDimensions = getImageDimensions(asset);
	$: expectedAspectRatio = imageDimensions.width / imageDimensions.height;

	let imgElement: HTMLImageElement;

	const calculatePoints = (points: ShopTheLookPointData[]) => {
		return points.map((point) => {
			if (!imgElement || !innerWidth) return point;
			const { width, height } = imgElement.getBoundingClientRect();
			const originalWidth = height * expectedAspectRatio;
			const originalHeight = width / expectedAspectRatio;
			const originalWidthSmaller = originalWidth < width;
			const originalHeightSmaller = originalHeight < height;

			// image is centered with object-cover, so we need to calculate the offset
			const pointX = (point.desktop || point.mobile || { x: 0 }).x;
			const pointY = (point.desktop || point.mobile || { y: 0 }).y;

			const recalibratedPoint = {
				productSKU: point.productSKU,
				x: originalWidthSmaller
					? width * pointX
					: pointX * originalWidth - (originalWidth - width) / 2,
				y: originalHeightSmaller
					? pointY * height
					: pointY * originalHeight - (originalHeight - height) / 2
			};
			return recalibratedPoint;
		});
	};

	$: recalibratedPoints = calculatePoints(points);

	onMount(() => {
		recalibratedPoints = calculatePoints(points);
	});
</script>

<svelte:window
	on:resize={() => {
		recalibratedPoints = calculatePoints(points);
	}}
/>

<div
	role="button"
	tabindex={0}
	on:click={() => {
		activeShopTheLookPoint.set(undefined);
	}}
	on:keypress={() => activeShopTheLookPoint.set(undefined)}
	class={classes(className, 'relative w-full h-full')}
>
	<Image
		on:load={() => {
			setTimeout(() => {
				recalibratedPoints = calculatePoints(points);
			}, 100);
		}}
		bind:ref={imgElement}
		class="w-full h-full absolute object-cover"
		src={asset.filename}
		alt={asset.alt}
		title={asset.title}
		width={imageDimensions.width}
		height={imageDimensions.height}
	/>

	{#each recalibratedPoints as point}
		<ShopTheLookPoint {...point} />
	{/each}
</div>
