<script lang="ts">
	import type { ShopTheLookProps } from '$lib/storyblok/types';
	import Image from '$lib/components/Image/Image.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import StoryblokVideo from '$lib/components/Storyblok/StoryblokVideo.svelte';
	import ShopTheLook from '$lib/components/Storyblok/ShopTheLook.svelte';

	export let video_id: string | undefined = undefined;
	export let autoplay: boolean | undefined = undefined;
	export let filename: string | null | undefined = undefined;
	export let shopTheLook: ShopTheLookProps | undefined = undefined;
	export let parallax: boolean | undefined = undefined;
	export let className: string | undefined = undefined;
	export let alt: string | null | undefined = undefined;
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let sizes: string | undefined = undefined;
	export let src: string | null | undefined = undefined;
	export let focus: string | undefined = undefined;
	export let title: string | undefined | null = undefined;

	export { className as class };
</script>

{#if video_id}
	<StoryblokVideo {video_id} autoplay={!!autoplay} class={className} />
{:else if shopTheLook?.asset}
	<ShopTheLook {...shopTheLook} {...$$restProps} class={className} />
{:else}
	<Image
		class={className}
		{focus}
		src={filename || src}
		{alt}
		{width}
		{height}
		{parallax}
		{sizes}
		{title}
		{...$$restProps}
	/>
{/if}
